import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const DoctorsCoachingPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "10.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				daniel: file(relativePath: { eq: "testimonials/Dr-Alice-Scott.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/louis-jones.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Doctors | Business & Executive Coaching in London";
	const description =
		"Navigating the complexities of running a successful healthcare practice requires more than expertise in medicine alone.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Doctors",
				item: {
					url: `${siteUrl}/industries/doctors`,
					id: `${siteUrl}/industries/doctors`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/doctors`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Doctors business & executive coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business & Executive Coach for Doctors"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							{/* <p>
                As a professional in a leadership position, you’re constantly
                juggling the priorities of your job with everything else in
                life. You’re likely responsible for strategic thinking,
                overseeing operations, developing new approaches, and much more.
              </p> */}
							<p>
								Navigating the complexities of running a successful healthcare
								practice requires more than expertise in medicine alone. You
								need a business focused partner who knows the industry, can
								assist in establishing and achieving business goals, and guide
								you through difficult choices. A business coach for doctors
								should be familiar with you and your medical practice, possess a
								thorough understanding of the healthcare sector, and appreciate
								the unique challenges faced by medical professionals.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Is medical business and executive coaching the solution?
							</h3>
							<p>
								With over 35 years of business expertise, I've engaged with
								numerous healthcare professionals, who face the distinctive
								challenges of managing their medical practices and leading their
								teams, all while striving for a more fulfilling professional and
								personal life. Many doctors and medical practitioners juggle the
								demands of patient care with the complexities of running a
								practice, making tough decisions daily, and seeking a balance
								that allows them to thrive both in their careers and at home. If
								you're a medical professional longing for a change, aiming to
								enhance your practice's efficiency, or seeking a more rewarding
								healthcare business, our tailored medical business and executive
								coaching services are designed to help you achieve just that.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								Your successful practice crystal ball...
							</h3>

							<p>
								Imagine for a moment, if you could peer into the future with
								perfect clarity, how would it make a significant difference to
								your professional life in the medical field if you...
							</p>
							<ul className="pl-4">
								<li>
									Grow your self-confidence in every decision and patient
									interaction?
								</li>
								<li>
									Develop your leadership and communication skills to new
									heights, enhancing team collaboration and patient care?
								</li>
								<li>
									Became more assertive and decisive, navigating the
									complexities of healthcare with ease?
								</li>
								<li>
									Felt a renewed surge of motivation and passion for your work,
									driving innovations in patient treatment and quality care?
								</li>
								<li>
									Took a bold step forward to invest in an innovative healthcare
									solution or practice improvement?
								</li>
							</ul>
							<p>
								Imagine the profound impact these changes could have on your
								medical practice, your patients, and your personal fulfilment.
							</p>
							<p>
								So, I pose this question to you: what barriers are currently
								preventing you from transforming these 'what ifs' into
								realities? Let's explore together how we can break down these
								barriers and set your path towards achieving these
								transformative business goals.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">What's holding you back?</h2>
							<ul className="pl-4">
								<li>
									Are you feeling isolated in your private practice, burdened by
									the weight of countless decisions and responsibilities?
								</li>
								<li>
									Facing pivotal decisions regarding the direction of your
									practice and medical business, and unsure which path to take?
								</li>
								<li>
									Eager to gain deeper insights into your core strengths and
									areas for improvement, and learn how to leverage them
									effectively?
								</li>
								<li>
									Noticing a decline in your usual levels of productivity and
									efficiency in your medical or healthcare business?
								</li>
								<li>
									Searching for that spark of passion and a deeper sense of
									purpose within your medical career?
								</li>
								<li>
									Or perhaps you find yourself stuck, searching for a
									breakthrough or a new direction in your professional journey?
								</li>
								<li>
									Do you have new ideas for enhancing your practice or patient
									care but seek an experienced, unbiased executive coach to
									explore these concepts further?
								</li>
							</ul>
							<p>
								In the fast-paced and demanding world of healthcare, it's common
								for medical professionals to encounter these obstacles. They are
								hurdles that, over time, can impact not only personal well-being
								as a business owner but also the effectiveness and growth of
								your practice.
							</p>
							<p>
								Confronting these obstacles head-on can significantly influence
								the trajectory of your career in medicine. When you find
								yourself stuck at such crossroads, making the right move is
								crucial.
							</p>
							<p>
								This is where my medical business and executive coaching comes
								into play.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								How business and executive coaching can point you in the right
								direction
							</h2>
							<p>
								As a London-based executive coach specialising in the healthcare
								sector, my mission is to support medical professionals and
								practices in navigating the complexities of the healthcare
								industry. By partnering together, we aim to refine your
								leadership skills, enhance operational efficiency, and foster
								professional growth within the demanding and dynamic medical
								environment.
							</p>
							<p>
								Offering flexible virtual business coaching sessions tailored to
								fit into your busy schedule, we focus on areas critical to
								medical practice success, including:
							</p>
							<ul className="pl-4">
								<li>Personal and Professional Development for Doctors</li>
								<li>Leadership Coaching Tailored to Medical Practice Owners</li>
								<li>Business Strategy and Management</li>
								<li>Career Advancement within the Medical Field</li>
							</ul>
							<p>
								My approach is distinct from other business coaches in London
								who may rely on a one-size-fits-all approach to coaching. Each
								session is customised to meet the unique needs of you or your
								medical practice, offering personalised guidance and strategies
								to navigate the right path as well as the opportunities unique
								to the healthcare sector.
							</p>
							<p>
								We are committed to working together, one on one, to set clear
								goals, maintain accountability, and achieve the outcomes your
								private practices desire, enabling you to create a positive
								impact on your practice or medical business, in addition to your
								patients' experience.
							</p>
							<p>
								Let's embark on a journey through coaching, to elevate your
								medical practice and achieve the excellence you strive for in
								the ever-evolving world of healthcare.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Why should you trust me?</h2>
							<p>
								Having dedicated my career to self-employment, I've experienced
								the full spectrum of what the professional journey entails—from
								the exhilarating highs to the inevitable lows.
							</p>
							<p>
								Leveraging my extensive background, which includes business
								qualifications (DMS & MBA), five years of counselling and
								psychotherapy training (Person-Centred and Transactional
								Analysis), alongside nearly four decades of business success, I
								am uniquely positioned to provide expert guidance and assist
								doctors and medical business owners.
							</p>
							<p>
								I understand the distinct challenges and pressures you face in
								the healthcare industry. My approach is designed to align your
								career aspirations and personal objectives, specifically
								tailored to navigate the complexities of being a medical
								practice business owner. Together, we can set a course to
								achieve the professional fulfilment and life balance you seek,
								ensuring your practice not only thrives but also reflects the
								high standards of care and excellence you aim to provide to your
								existing and new patients.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Reviews from my business and executive coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Dr Alice Scott. General Practitioner and Menopause Specialist. Essex Private Doctors."
						review="Having launched my business, the enormity of the ongoing management, growth and business development felt overwhelming. Starting coaching with Jason is the best business decision I have made so far. His calm, methodical and analytical approach has been invaluable. Sometimes it is easy to get caught up in a situation yourself, making you prone to emotional decision making, and I have found that Jason has been a good sounding board. He is encouraging to help you to push on with what your ultimate goals are. I am still having regular business coaching with Jason."
						linkedIn="https://www.linkedin.com/in/dr-alice-scott-45b268118/"
						profilePic={daniel}
					/>

					<HorizontalTestimonial
						name="Louis Jones. Chartered Physiotherapist."
						review="I have been working with Jason for about 1 year now and I find his advice and expertise second to none. He made an instant impact on my business, when we started working together, by banishing any negative thought processes and false beliefs I had about money and I saw an increase in sales. He sets achievable goals, which he also holds you accountable for and discusses any concerns and worries that I may have, always putting my mind at ease. Being a business owner himself, he really understands what it is like to run a business. I would thoroughly recommend Jason."
						linkedIn="https://www.linkedin.com/in/louisljones/"
						profilePic={andrew}
					/>

					<div className="iframe-container-new mb-4">
						<iframe
							loading="lazy"
							className="responsive-iframe"
							src="https://www.youtube.com/embed/pzRLZ_GvIN8?si=Gz_pmLZq3ukyE6mg"
							title="Testimonial | Jason Cornes"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to work towards a positive future?
							</h2>
							<p>
								In the world of healthcare, the future is often as unpredictable
								as it is promising. While I may not have the ability to foresee
								the outcomes of enhancing patient care, expanding your practice,
								or navigating the complexities of medical leadership with
								absolute certainty, one truth remains clear:
							</p>
							<p>If no action is taken, no progress will be made.</p>
							<p>
								However, the potential for transformation within your practice
								and professional life is immense when we harness the power of
								targeted coaching to concentrate on your specific goals and
								manage your next steps.
							</p>
							<p>
								With the stakes so high and yet nothing to lose, why not
								schedule an introductory call with me? Together, we can explore
								the pathways to not only meet but exceed your aspirations for
								your medical business.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">
								Book a call today with your new business coach
							</h3>
							<p>
								Let's chat, get to know each other and see if there's a way for
								us to work together with some business and executive coaching
								for doctors and medical business owners.
							</p>
							<p>
								P.S. Remember, no matter how good you are in your role as a
								medical business owner, you know full well that you can't do it
								single-handedly. You need a trusted medical business coaching
								partner who can provide objective feedback, help you set and
								accomplish goals, and help you make tough decisions with the
								support you require.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default DoctorsCoachingPage;
